<template>
  <img :src="getSrc()" :srcset="getSrcSet()" :height="`${size * 10}px`" />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      require: true,
      default: 2
    },
    type: {
      type: String,
      default: "png"
    }
  },
  methods: {
    getSrc() {
      return require(`@/assets/img/${this.name}@3x.${this.type}`);
    },
    getSrcSet() {
      return (
        require(`@/assets/img/${this.name}@2x.${this.type}`) +
        " 2x," +
        require(`@/assets/img/${this.name}@3x.${this.type}`) +
        " 3x"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
//TO DO SOMETHING
</style>
