import Vue from "vue";
import Vuex from "vuex";
import { make } from "vuex-pathify";
import pathify from "./pathify";

import auth from "./modules/auth";
export const state = () => ({});

export const mutations = {
  ...make.mutations(state)
};

export const getters = {
  ...make.getters(state)
};
export const actions = {
  ...make.actions(state)
};

Vue.use(Vuex);
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins: [pathify.plugin],
  modules: {
    auth
  }
});
