import VueCompositionApi from "@vue/composition-api";
import Antd, { message } from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import Vue from "vue";
import App from "./App.vue";

import "./class-component-hooks";
import "./plugins/v-clipboard.ts";
import "./plugins/vee-validate.ts";
import "./plugins/vue-moment.ts";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueCompositionApi);

message.config({ maxCount: 1 });

declare module "@vue/composition-api/dist/component/component" {
  interface SetupContext {
    readonly refs: { [key: string]: Vue | Element | Vue[] | Element[] };
  }
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
