import FullPageLayout from "@/layouts/FullPageLayout.vue";
import SideBarLayout from "@/layouts/SideBarLayout.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authGuard } from "./auth-guard";
import { guestGuard } from "./guest-guard";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    component: SideBarLayout,
    children: [
      {
        path: "/",
        name: "home",
        beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home.vue")
      },
      {
        path: "/settings",
        name: "setting",
        beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "organization" */ "../views/GlobalSetting.vue")
      },
      {
        path: "/organization",
        name: "organization",
        beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "organization" */ "../views/Organization.vue")
      },
      {
        path: "/role",
        name: "role",
        beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "role" */ "../views/Role.vue")
      },
      {
        path: "/room",
        name: "room",
        beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "room" */ "../views/room/Room.vue")
      },
      {
        path: "/room-by-participant",
        name: "participant-room",
        beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "room" */ "../views/room/FilterRoom.vue")
      },
      {
        path: "/room/:id/participate",
        name: "room-id-participate",
        beforeEnter: authGuard,
        component: () =>
          import(
            /* webpackChunkName: "room-id-participate" */ "../views/room/RoomParticipate.vue"
          )
      },
      {
        path: "/room/:id/detail",
        name: "room-id-detail",
        beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "room-id-detail" */ "../views/room/RoomDetail.vue")
      },
    ]
  },
  {
    path: "/",
    component: FullPageLayout,
    children: [
      {
        path: "/login",
        name: "login",
        beforeEnter: guestGuard,
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Login.vue")
      },
      {
        path: "/profile",
        name: "profile",
        beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
      },
      {
        path: "/register",
        name: "register",
        beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "register" */ "../views/Register.vue")
      },
      {
        path: "/reset-password",
        name: "reset-password",
        beforeEnter: guestGuard,
        component: () =>
          import(/* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue")
      },
      {
        path: "/my-organization",
        name: "my-organization",
        beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "my-organization" */ "../views/MyOrganization.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
