import * as AuthService from "@/services/auth.service";
import store from "@/store";
import { NavigationGuardNext, Route } from "vue-router";
const routeForSuperAdmin = ["register"];
export const authGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const isAuthenticated = !!AuthService.getToken("space_access_token");
  if (!isAuthenticated) {
    return next({ name: "login" });
  }
  const user = store.getters["auth/user"];
  if (user && !user.role) {
    const { data, code } = await store.dispatch("auth/loadProfile");
    if (code && code !== 200) {
      await AuthService.removeToken("space_access_token");
      return next({ name: "login" });
    }
    const isRouteForSuperAdmin = routeForSuperAdmin.some(
      route => route === to.name
    );
    if (isRouteForSuperAdmin && data.role !== "superadmin") {
      return next({ name: "my-organization" });
    }
  }
  return next();
};
