<template>
  <a-layout class="layout">
    <a-layout-header class="d-flex justify-content-between align-items-center">
      <img-asset name="globish-space-logo" :size="3"></img-asset>
      <div class="d-flex">
        <a-dropdown v-if="user && user.id">
          <a-avatar
            v-if="user.avatar"
            :size="32"
            :src="user.avatar"
            class="mr-4 _pointer-on-hover"
            @click="e => e.preventDefault()"
          />
          <a-avatar
            v-else
            :size="32"
            icon="user"
            class="mr-4 _pointer-on-hover"
            @click="e => e.preventDefault()"
          />
          <a-menu slot="overlay">
            <a-menu-item class="d-flex align-items-center">
              <div>
                <a-avatar :size="32" icon="user" :src="user.avatar" />
              </div>
              <div class="ml-3">
                {{ user.username }}
              </div>
            </a-menu-item>
            <a-menu-item>
              <router-link to="/my-organization"
                >Change organization</router-link
              >
            </a-menu-item>
            <a-menu-item>
              <router-link to="/profile">Edit</router-link>
            </a-menu-item>
            <a-menu-item @click="logout">Logout</a-menu-item>
          </a-menu>
        </a-dropdown>
        <router-link v-else to="/login" class="btn btn-link">Login</router-link>
      </div>
    </a-layout-header>
    <a-layout-content class="container">
      <div class="main-content-layout">
        <router-view />
      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center">
      Space Admin ©2020 Created by Globish Academia
    </a-layout-footer>
  </a-layout>
</template>

<script>
import ImgAsset from "@/components/common/ImgAsset";
import * as AuthService from "@/services/auth.service";
import { call, get } from "vuex-pathify";
export default {
  components: { ImgAsset },
  computed: {
    isAuthen() {
      return (
        this.$route.name === "profile" || this.$route.name === "my-organization"
      );
    },
    user: get("auth/user")
  },
  methods: {
    clearProfile: call("auth/clearProfile"),
    async logout() {
      await AuthService.removeToken("space_access_token");
      this.clearProfile();
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style lang="scss" scoped>
// .ant-menu-item {
//   color: rgba(255, 255, 255, 0.65) !important;
//   &.ant-menu-item-selected,
//   &.ant-menu-item-active {
//     color: white !important;
//   }
// }
.side-bar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
}
.layout {
  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.main-content-layout {
  margin: 24px 16px;
  padding: 24px;
  border-radius: 4px;
  @include primary-box-shadow;
  background: white;
}
.btn-link {
  color: $primary-color;
  &:hover {
    color: #40a9ff;
  }
}
</style>
