<template>
  <a-layout
    id="components-layout-demo-custom-trigger"
    class="layout-main-with-sidebar"
  >
    <a-layout-sider
      v-model="collapsed"
      breakpoint="lg"
      collapsed-width="0"
      @collapse="onCollapse"
      @breakpoint="onBreakpoint"
      :trigger="null"
      collapsible
    >
      <router-link to="/">
        <div class="side-bar-logo">
          <img
            v-show="collapsed"
            src="~@/assets/img/globish-logo.png"
            width="40px"
          />
          <img-asset
            v-show="!collapsed"
            name="globish-space-logo"
            :size="3"
          ></img-asset>
        </div>
      </router-link>

      <a-menu
        theme="dark"
        mode="inline"
        v-model="selectedList"
        :key="`${selectedList[0]}-menu-active`"
        :open-keys.sync="openKeys"
      >
        <template v-for="sideItem in sideBarList">
          <a-menu-item v-if="sideItem.type === 'default'" :key="sideItem.key">
            <router-link :to="{ name: sideItem.routeName }">
              <a-icon :type="sideItem.icon" />
              <span>{{ sideItem.name }}</span>
            </router-link>
          </a-menu-item>
          <a-sub-menu v-if="sideItem.type === 'sub'" :key="sideItem.key">
            <span slot="title"
              ><a-icon :type="sideItem.icon" />{{ sideItem.name }}</span
            >
            <a-menu-item v-for="child in sideItem.children" :key="child.key">
              <router-link :to="{ name: child.routeName }">
                {{ child.name }}
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0;">
        <div class="d-flex justify-content-between align-items-center">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
          <a-dropdown v-if="user">
            <a-avatar
              :size="32"
              icon="user"
              :src="user.avatar"
              class="mr-4 _pointer-on-hover"
              @click="e => e.preventDefault()"
            />
            <a-menu slot="overlay">
              <a-menu-item class="d-flex align-items-center">
                <div>
                  <a-avatar :size="32" icon="user" :src="user.avatar" />
                </div>
                <div class="ml-3">
                  {{ user.username }}
                </div>
              </a-menu-item>
              <a-menu-item>
                <router-link to="/my-organization"
                  >Change organization</router-link
                >
              </a-menu-item>
              <a-menu-item>
                <router-link to="/profile">Edit</router-link>
              </a-menu-item>
              <a-menu-item @click="logout">Logout</a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content>
        <div class="main-content-layout"><router-view /></div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import ImgAsset from "@/components/common/ImgAsset";
import * as AuthService from "@/services/auth.service";
import { call, get } from "vuex-pathify";
export default {
  name: "SideBarLayout",
  components: { ImgAsset },
  data() {
    return {
      collapsed: false,
      sideBarList: [
        {
          type: "default",
          icon: "setting",
          routeName: "setting",
          name: "Global Config",
          key: "sidebar-setting"
        },
        {
          type: "default",
          icon: "setting",
          routeName: "organization",
          name: "Organization",
          key: "sidebar-organization"
        },
        {
          type: "default",
          icon: "user",
          routeName: "role",
          name: "Role",
          key: "sidebar-role"
        },
        {
          type: "sub",
          icon: "video-camera",
          name: "Room",
          key: "sidebar-room-group",
          children: [
            { routeName: "room", name: "All Room", key: "sidebar-all-room" },
            {
              routeName: "participant-room",
              name: "Filter Participant Room",
              key: "sidebar-participant-room"
            }
          ]
        }
      ],
      allRoutes: [],
      selectedList: [],
      openKeys: []
    };
  },
  computed: {
    user: get("auth/user")
  },
  watch: {
    async $route() {
      await this.changeTab();
    }
  },
  async mounted() {
    this.allRoutes = this.sideBarList.reduce((result, current) => {
      if (current.type === "default") return result.concat(current);
      else return result.concat(current.children);
    }, []);
    await this.changeTab();
  },
  methods: {
    clearProfile: call("auth/clearProfile"),
    async logout() {
      await AuthService.removeToken("space_access_token");
      this.clearProfile();
      this.$router.push({ name: "login" });
    },
    // eslint-disable-next-line no-unused-vars
    onCollapse(collapsed, type) {
      // console.log(collapsed, type);
    },
    // eslint-disable-next-line no-unused-vars
    onBreakpoint(broken) {
      // console.log(broken);
    },
    async changeTab() {
      const selectedKey = await this.allRoutes.filter(({ routeName }) => {
        if (this.$route.name === routeName) {
          return routeName;
        }
      });
      if (selectedKey.length > 0) {
        this.selectedList = [selectedKey[0].key];
        const openKeys = await this.sideBarList.filter(sidebar => {
          if (sidebar.type === "default") {
            return this.$route.name === sidebar.routeName;
          } else {
            return sidebar.children.some(
              child => child.key === this.selectedList[0]
            );
          }
        });
        if (openKeys.length > 0) {
          this.openKeys = [openKeys[0].key];
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// .ant-menu-item {
//   color: rgba(255, 255, 255, 0.65) !important;
//   &.ant-menu-item-selected,
//   &.ant-menu-item-active {
//     color: white !important;
//   }
// }
.side-bar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
}
.main-content-layout {
  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  margin: 24px 16px;
  padding: 24px;
  background: white;
}
</style>
