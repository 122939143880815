import AdminService from "@/services/admin.service";
import { make } from "vuex-pathify";

function state() {
  return {
    user: {}
  };
}

const getters = {
  ...make.getters(state)
};

const mutations = {
  ...make.mutations(state)
};

const actions = {
  ...make.actions(state),
  async loadProfile({ dispatch }: any) {
    const response = await AdminService.getProfile();
    dispatch("setUser", response.data);
    return response;
  },
  async clearProfile({ dispatch }: any) {
    dispatch("setUser", {});
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
