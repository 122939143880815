import ApiService, { ApiServiceResponse } from "./api.service";
import * as AuthService from "./auth.service";
import { GetOrganizationResponse } from "./organization.service";

interface RegisterPayload {
  username?: string;
  password?: string;
  email?: string;
  role?: string;
  organizationId?: string;
}

interface LoginPayload {
  identity: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
}

export interface OrganizationMe extends GetOrganizationResponse {
  apiKey: string;
  apiSecret: string;
}

interface LoginWithOrganizationPayload {
  apiKey: string;
  apiSecret: string;
}
interface ResetPasswordPayload {
  identity: string;
  newPassword: string;
  confirmPassword: string;
}

export interface CreateOrganizationPayload {
  name?: string;
  logoPath?: string;
  favicon?: string;
  color?: string;
  engine?: "zoom" | "twilio" | "tokbox" | "whereby";
  fallbackEngine?: "zoom" |  "twilio" | "tokbox" | "whereby";
  exitPath?: string;
  domain?: string;
  webhookUrl?: string;
  supportEmail?: string;
  supportTel?: string;
  attendBefore?: number;
  attendAfter?: number;
}

interface EditProfilePayload {
  organizationId?: string;
  fullname?: string;
  avatar?: string;
}
export interface ServiceEnginePayload {
  twilioAccountSid: string;
  twilioApiKeySid: string;
  twilioApiKeySecret: string;
  tokboxApiKey: string;
  tokboxApiSecret: string;
  awsTokboxAccessKeyId: string;
  awsTokboxSecretAccessKey: string;
}

export enum GlobalUrgentEngineEnum {
  DEFAULT = "",
  TOKBOX = "tokbox",
  TWILIO = "twilio",
  WHEREBY = "whereby"
}
interface GetUrgentEngineReseponse {
  id: number;
  name: string;
  value: GlobalUrgentEngineEnum;
}
export default class AdminService {
  public static async login(
    payload: LoginPayload
  ): Promise<ApiServiceResponse> {
    const res = await this.apiService.post(`/Admins/Login`, payload);
    if (res.status === 201) {
      const { accessToken } = res.data as LoginResponse;
      const token = `Bearer ${accessToken}`;
      await AuthService.saveToken("space_access_token", token);
    }
    return res;
  }

  public static async register(
    payload: RegisterPayload
  ): Promise<ApiServiceResponse> {
    const res = await this.apiService.post(`/Admins/Register`, payload);
    return res;
  }

  public static async getOrganizationMe(): Promise<OrganizationMe[]> {
    const { data } = await this.apiService.get(`/Admins/Organizations`);
    if (data) {
      if (data.length) {
        return data;
      } else {
        return [data];
      }
    }
    return [];
  }

  public static async loginWithOrganization({
    apiKey,
    apiSecret
  }: LoginWithOrganizationPayload): Promise<boolean> {
    const token = "Basic " + btoa(apiKey + ":" + apiSecret);
    await AuthService.saveToken("space_org_access_token", token);
    return true;
  }

  public static async getProfile(): Promise<ApiServiceResponse> {
    const res = await this.apiService.get(`/Admins/Me`);
    return res;
  }

  public static async editProfile(
    payload: EditProfilePayload
  ): Promise<ApiServiceResponse> {
    const res = await this.apiService.patch(`/Admins`, payload);
    return res;
  }

  public static async resetPassword({
    identity,
    newPassword,
    confirmPassword
  }: ResetPasswordPayload): Promise<ApiServiceResponse> {
    const data = await this.apiService.post(`/Admins/ResetPassword`, {
      identity,
      newPassword,
      confirmPassword
    });
    return data;
  }

  public static async createOrganization(
    payload: CreateOrganizationPayload
  ): Promise<ApiServiceResponse> {
    const res = await this.apiService.post(`Admins/Organizations`, payload);
    return res;
  }

  public static async updateServiceEngine(
    organizationId: string,
    payload: ServiceEnginePayload
  ): Promise<ApiServiceResponse> {
    const res = await this.apiService.patch(
      `/Admins/Organizations/${organizationId}/ServiceEngine`,
      payload
    );
    return res;
  }

  public static async getUrgentEngine(): Promise<GlobalUrgentEngineEnum> {
    const res = await this.apiService.get(`/Configs/Engine`);
    let data: GlobalUrgentEngineEnum = GlobalUrgentEngineEnum.DEFAULT;
    if (res.data) {
      const detail = res.data as GetUrgentEngineReseponse;
      data = detail.value;
    }
    return data;
  }

  public static async changeUrgentEngine(
    engine: GlobalUrgentEngineEnum
  ): Promise<ApiServiceResponse> {
    const res = await this.apiService.patch(`/Configs/Global?engine=${engine}`);
    return res;
  }
  private static apiService = new ApiService("admin");
}
