import Cookies from "js-cookie";
const domain =
  process.env.NODE_ENV === "production" ? process.env.DOMAIN : "localhost";
const COOKIE_OPTION = {
  domain,
  expires: 90,
  secure: process.env.NODE_ENV === "production" ? true : false
};

export const getToken = (tokenKey: string) => {
  return Cookies.get(tokenKey);
};

export const saveToken = (
  tokenKey: string,
  accessToken: string
) => {
  Cookies.remove(tokenKey, { domain });
  Cookies.set(tokenKey, accessToken, COOKIE_OPTION);
};

export const removeToken = (tokenKey: string) => {
  Cookies.remove(tokenKey, { domain });
};
