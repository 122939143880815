import * as AuthService from "@/services/auth.service";
import { NavigationGuardNext, Route } from "vue-router";
export const guestGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const isAuthenticated = !!AuthService.getToken("space_access_token");
  if (isAuthenticated && to.name !== "reset-password") {
    next({ name: "my-organization" });
    return;
  }
  next();
};
