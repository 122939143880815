import { extend, ValidationProvider } from "vee-validate";
import Vue from "vue";

// Add a rule.
extend("secret", {
  validate: value => value === "example",
  message: "This is not the magic word"
});
extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1
    };
  },
  message: field => `${field} is required`,
  computesRequired: true
});
// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
