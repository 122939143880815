import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import * as AuthService from "./auth.service";
export interface ApiServiceResponse extends AxiosResponse {
  error?: AxiosError;
  validation?: any;
  code?: number;
}
const BASE_URL =
  process.env.BACKEND_BASE_URL || "https://space-backend.globish.co.th";

const HEADERS = {
  "Accept": "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*"
};

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common = HEADERS;

axios.interceptors.request.use(
  async config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  res => res,
  error => {
    const err = <ApiServiceResponse> {
      error,
      validation: error.response.data.message?.inputError || error.response.data.message?.message,
      code: error.response.data.statusCode
    };
    return err;
  }
);

export default class ApiService {
  private authType: string;

  constructor(type: string) {
    // type = admin | partner
    this.authType = type;
  }

  public get(url: string, config?: AxiosRequestConfig) {
    const decoratedConfig = this.decorateConfig(url, config);
    return axios.get(url, decoratedConfig);
  }

  public post(url: string, data?: any, config?: AxiosRequestConfig) {
    const decoratedConfig = this.decorateConfig(url, config);
    return axios.post(url, data, decoratedConfig);
  }

  public put(url: string, data?: any, config?: AxiosRequestConfig) {
    const decoratedConfig = this.decorateConfig(url, config);
    return axios.put(url, data, decoratedConfig);
  }

  public patch(url: string, data?: any, config?: AxiosRequestConfig) {
    const decoratedConfig = this.decorateConfig(url, config);
    return axios.patch(url, data, decoratedConfig);
  }

  public delete(url: string, config?: AxiosRequestConfig) {
    const decoratedConfig = this.decorateConfig(url, config);
    return axios.delete(url, decoratedConfig);
  }
  private decorateConfig(
    url: string,
    config?: AxiosRequestConfig
  ): AxiosRequestConfig | undefined {
    if (/space-backend.*\.globish.co.th/.test(url) || !/^http/.test(url)) {
      // Pass cookies with requests for sticky session (space-backend only)
      const tokenKey =
        this.authType === "admin"
          ? "space_access_token"
          : "space_org_access_token";
      const token = AuthService.getToken(tokenKey);
      return {
        ...config,
        headers: { Authorization: token },
        withCredentials: true
      };
    }
    return config;
  }
}
